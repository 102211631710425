import { useQuery } from "@apollo/client";
import { useState } from "react";
import { useNavigate, useSearch } from "react-location";
import { GET_SERVICE_ORDER } from "./apollo";
import { LocationGenerics } from "router/location";
import { Modal, ServiceOrderHistoryView } from "components";
import { classNames, wrapClick } from "utils";
import {
  InspectionServiceOrderDetails,
  InspectionServiceOrderResolutionDetails,
} from "components/cards/inspection-service-order-view";

const orderTabs = [
  {
    name: "Order Details",
    href: "OrderDetails",
    activeStatues: [
      "Pending",
      "Assigned",
      "Resolved",
      "Completed",
      "InProgress",
    ],
  },
  {
    name: "Order History",
    href: "OrderHistory",
    activeStatues: [
      "Pending",
      "Assigned",
      "Resolved",
      "Completed",
      "InProgress",
      "Disapproved",
    ],
  },
  {
    name: "Resolution Details",
    href: "ResolutionDetails",
    activeStatues: ["Resolved", "Disapproved", "Completed"],
  },
];

export default function ViewInspectionServiceOrderContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [orderTab, setOrderTab] = useState("OrderDetails");
  const { data, loading } = useQuery(GET_SERVICE_ORDER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only",
  });

  const __setOrderTab = (tab: string) => () => {
    setOrderTab(tab);
  };

  return (
    <Modal
      open={open}
      setOpen={() => {
        setOpen(false);
        navigate({
          search: (old) => ({
            ...old,
            modal: undefined,
            id: undefined,
          }),
        });
      }}
      hideActions={false}
      hidePadding={true}
      loading={loading}
      title="Service Order Information"
      size="4xl"
      description="Details of service order are shown below"
    >
      {data?.serviceOrder?._id && (
        <>
          {[
            "Assigned",
            "InProgress",
            "Resolved",
            "Completed",
            "Disapproved",
          ].includes(data?.serviceOrder?.status) && (
            <div className="block">
              <div className="border-b border-gray-200 bg-white px-6">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {orderTabs.map((_orderTab) => (
                    <button
                      key={_orderTab.name}
                      onClick={wrapClick(__setOrderTab(_orderTab.href))}
                      disabled={
                        !_orderTab.activeStatues.includes(
                          data?.serviceOrder?.status,
                        )
                      }
                      className={classNames(
                        orderTab === _orderTab.href
                          ? "border-primary-500 text-primary-600"
                          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                        "whitespace-nowrap disabled:cursor-not-allowed flex py-4 px-1 border-b-2 font-medium text-sm",
                      )}
                      aria-current={
                        orderTab === _orderTab.href ? "page" : undefined
                      }
                    >
                      {_orderTab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          )}
          <div className="flex-1 w-full min-h-[65vh] max-h-[65vh] overflow-y-auto  sm:p-6">
            {orderTab === "OrderDetails" && (
              <InspectionServiceOrderDetails
                serviceOrder={data?.serviceOrder}
              />
            )}
            {orderTab === "OrderHistory" && (
              <ServiceOrderHistoryView history={data?.serviceOrder?.history} />
            )}
            {orderTab === "ResolutionDetails" && (
              <InspectionServiceOrderResolutionDetails
                region={data?.serviceOrder?.region}
                district={data?.serviceOrder?.district}
                resolution={data?.serviceOrder?.resolution}
                result={data?.serviceOrder?.result || ""}
              />
            )}
          </div>
        </>
      )}
    </Modal>
  );
}
