import React, { useCallback, useEffect, useState } from "react";
import {useDropzone, Accept, ErrorCode, FileError} from "react-dropzone";
import axios from "axios";
import config from "config";
import _ from "lodash";
import { classNames } from "utils";

axios.defaults.baseURL = config.asset.uri;

interface UploadAreaProps {
  id: string;
  multiple?: boolean;
  maxFiles?: number;
  maxSize?: number;
  minSize?: number;
  accept?: Accept;
  disabled?: boolean;
  label?: string;
  values: any;
  setFieldValue: any;
  setFieldError: any;
  handleBlur: any;
  errors?: any;
  touched?: any;
  className?: string;
  required?: boolean;
}

interface Upload extends File {
  preview: string
}

const UploadArea: React.FC<UploadAreaProps> = ({
  multiple = false,
  maxFiles = 1,
  maxSize = 1024 * 1024,
  minSize = 1,
  accept = {
    "image/*": [".png", ".jpeg", ".jpg"],
  },
  errors,
  values,
  setFieldValue,
  touched,
  id,
  label,
  className = "aspect-w-3 aspect-h-2 w-ful",
  required = false,
  setFieldError,
}) => {
  const [files, setFiles] = useState<Upload[]>([]);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {

    // react-drop-zone automatically omits rejected files.
    if(acceptedFiles.length === 0){
      return;
    }

    setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));

    // upload image to server and return url
    if (multiple) {
      const imageUrls: string[] = [];
      for (let i = 0; i < acceptedFiles.length; i++) {
        const data = new FormData();
        const file = acceptedFiles[i];
        data.append("file", file);
        await axios.post("/upload", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        })
          .then(({ data }) => {
            imageUrls.push(data);
          })
          .catch((err) => {

          });
      }
      if (imageUrls.length) setFieldValue?.(id, imageUrls);
    }
    else {
      const data = new FormData();
      const file = acceptedFiles[0];
      data.append("file", file);
      await axios.post("/upload", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      })
        .then(({ data }) => {
          if (data) setFieldValue?.(id, data as string);
        })
        .catch((err) => {

        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // files,
    multiple
  ]);

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop,
    maxFiles,
    multiple,
    accept,
    maxSize,
    minSize,
    noClick: false
  });

  useEffect(
    () => () => {
      // Make sure to revoke the Object URL to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file?.preview));
    },
    [files]
  );

  function resolveDropZoneErrorCode(error: FileError): string {
    switch (error.code) {
      case ErrorCode.FileTooLarge:
        return "File is larger than " + (Math.round(maxSize / (1024 * 1024)).toLocaleString('en', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 0,
        })) + "MB";
      case ErrorCode.FileTooSmall:
        return "File is smaller than " + (Math.round(minSize / (1024 * 1024)).toLocaleString('en', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 0,
        })) + "MB";
      case ErrorCode.FileInvalidType:
        return "File has invalid type. Valid types are: " + Object.values(accept).reduceRight((previousValue, currentValue) => [...previousValue, ...currentValue]).join(", ");
      case ErrorCode.TooManyFiles:
        return "Too many files have been uploaded";
      default:
        return "File was rejected";
    }
  }

  return (
    <>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label} {required ? <span className="text-red-600">*</span> : ""}
      </label>
      <div
        {...getRootProps()}
        className={classNames(
          className,
          "relative group flex-1 flex border-2 mt-1 border-gray-300 border-dashed rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        )}
      >
        <input {...getInputProps()} id="file" name="file" type="file" className="sr-only" />
        {(!files.length && (multiple ? !_.get(values, id)?.length : !_.get(values, id))) ? (
          !isDragActive ? (
            <div className="space-y-1 flex flex-1 flex-col items-center justify-center text-center px-6 pt-5 pb-6">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="flex text-sm justify-center text-gray-600">
                <span
                  className="text-center cursor-pointer bg-white rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                >
                  Upload a file
                </span>
              </div>
              <p className="text-xs text-gray-500">or drag and drop</p>
            </div>
          ) : (
            <div className="space-y-1 flex flex-1 flex-col items-center justify-center text-center px-6 pt-5 pb-6">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="text-xs text-gray-500">Drop File Here</p>
            </div>
          )
        ) : (
          <>
            {multiple ? (
              <div className="flex-1 grid-cols-3 grid gap-3 gap-y-0 p-2">
                {(files.length ? files : _.get(values, id)).map((file: any) => (
                  <div>
                    <img
                      className="mt-0 object-cover object-top w-full h-1/2 border-2"
                      src={file?.preview || file}
                      alt="Hello"
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="">
                <img
                  className="object-cover object-top h-full w-full z-0"
                  src={files?.[0]?.preview || (multiple ? _.get(values, id)?.[0] : _.get(values, id))}
                  alt="Hello"
                />
              </div>
            )}
            {!isDragActive ? (
              <div className="absolute inset-0 opacity-0 flex flex-1 flex-col items-center justify-center group-hover:opacity-75 space-y-1 z-10 text-center bg-gray-50 px-6 pt-5 pb-6 m-0">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="flex text-sm justify-center text-gray-600">
                  <span
                    className="text-center cursor-pointer  rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                  >
                    Upload a file
                  </span>
                </div>
                <p className="text-xs text-gray-700">or drag and drop</p>
              </div>
            ) : (
              <div className="absolute inset-0 opacity-0 group-hover:opacity-75 space-y-1 z-10 text-center bg-gray-50 px-6 pt-5 pb-6">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="text-xs text-gray-500">Drop File Here</p>
              </div>
            )}
          </>
        )}
      </div>
      {Boolean(fileRejections?.length) && fileRejections.map(({ file, errors}, idx) => (
          <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
            Error with <strong>{file.name}</strong>: <br/>
            { errors.map((err) => (
              <>
                <span className="text-red-500">
                  {resolveDropZoneErrorCode(err)}
                </span>
                <br />
              </>
            )) }
          </p>
        )
      )}
      {_.get(errors, id) && _.get(touched, id) ? (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {_.get(errors, id)}
        </p>) : null
      }
    </>
  );
};

export default UploadArea;
